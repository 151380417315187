var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('section',[_c('p',{staticClass:"text-caption mb-2 d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.phone"))+" "),_c('Menu',{staticClass:"flex-shrink-0",attrs:{"open-on-click":_vm.$vuetify.breakpoint.mobile,"open-on-hover":!_vm.$vuetify.breakpoint.mobile,"top":"","right":"","offset-y":"","open-delay":"100","close-delay":"200","nudge-right":"-105","nudge-top":"10","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("$questionC")])],1)]}},{key:"content",fn:function(){return [_c('v-card',{staticClass:"pa-2 white--text text-caption",attrs:{"max-width":"320","color":"rgba(64, 64, 65, 0.9)"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.t(
                  '$vuetify.dashboard.phone_number.used_for_vidar'
                )
              )}})])]},proxy:true}])})],1),_c('PhoneNumberTextField',{attrs:{"country":_vm.localPhoneNumber.country,"prefix":_vm.localPhoneNumber.prefix,"rules":[_vm.fieldRules.phoneNumber],"outlined":"","dense":""},on:{"update:country":function($event){return _vm.$set(_vm.localPhoneNumber, "country", $event)},"update:prefix":function($event){return _vm.$set(_vm.localPhoneNumber, "prefix", $event)},"change:complete":_vm.onChangeComplete,"accept":_vm.onAccept},model:{value:(_vm.localPhoneNumber.number),callback:function ($$v) {_vm.$set(_vm.localPhoneNumber, "number", $$v)},expression:"localPhoneNumber.number"}})],1),_c('v-btn',{staticClass:"ml-auto d-flex mt-2",attrs:{"disabled":_vm.disabledForm,"loading":_vm.loading,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.confirmation_code.get"))+" ")]),_c('ConfirmDialog',{attrs:{"loading":_vm.loading},on:{"confirm":_vm.updatePhoneNumber},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            '$vuetify.dashboard.phone_number.sure_want_change',
            `<span class='text-no-wrap'>${_vm.formatedLocalPhoneNumber}</span>`
          )
        )}})]},proxy:true}]),model:{value:(_vm.dialogConfirmUpdatePhoneNumber),callback:function ($$v) {_vm.dialogConfirmUpdatePhoneNumber=$$v},expression:"dialogConfirmUpdatePhoneNumber"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }