import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { VForm } from "@/types/vuetify";
import { profileModule } from "@store/namespaces";
import { ProfileActions } from "@store/modules/profile/types";
import { PhoneNumber } from "@/types/phoneNumber";
import { AxiosError } from "axios";
import { ApiErrorResponse, ApiError } from "@/types/api";
import { formatPhoneNumber } from "@helpers";

@Component({
  components: {
    PhoneNumberTextField: () =>
      import("@/components/PhoneNumberTextField/PhoneNumberTextField.vue"),
  },
})
export default class PhoneNumberForm extends Vue {
  @profileModule.Action("updateProfile")
  private readonly updateProfileAction!: ProfileActions["updateProfile"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: Object, required: true })
  private readonly phoneNumber!: PhoneNumber;

  private loading = false;
  private dialogConfirmUpdatePhoneNumber = false;
  private isCompletedPhoneNumber = false;
  private isInvalidPhoneNumber = false;
  private localPhoneNumber: PhoneNumber = {
    country: "",
    prefix: "",
    number: "",
    approved: false,
  };

  private get fieldRules() {
    return {
      phoneNumber: () => {
        if (!this.isCompletedPhoneNumber) {
          return this.$vuetify.lang.t("$vuetify.errors.phone_number.required");
        }

        if (this.isInvalidPhoneNumber) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.phone_number.busy_and_try_another"
          );
        }

        return true;
      },
    };
  }

  private get disabledForm() {
    return (
      this.localPhoneNumber.number === this.phoneNumber.number &&
      this.localPhoneNumber.country === this.phoneNumber.country &&
      this.localPhoneNumber.prefix === this.phoneNumber.prefix &&
      this.phoneNumber.approved
    );
  }

  private get formatedLocalPhoneNumber() {
    return formatPhoneNumber(this.localPhoneNumber);
  }

  private onAccept() {
    this.isInvalidPhoneNumber = false;
  }

  private onChangeComplete(completed: boolean) {
    this.isCompletedPhoneNumber = completed;
  }

  private onSubmitForm() {
    if (!this.formRef.validate() || this.disabledForm) return;

    if (this.localPhoneNumber.number && this.localPhoneNumber.approved) {
      this.dialogConfirmUpdatePhoneNumber = true;
    } else {
      this.updatePhoneNumber();
    }
  }

  private async updatePhoneNumber() {
    if (this.loading) return;

    this.loading = true;

    try {
      await this.updateProfileAction({
        isAxiosNotify: false,
        phone: this.localPhoneNumber,
      });

      this.loading = false;
      this.dialogConfirmUpdatePhoneNumber = false;

      this.$emit("updated");
    } catch (e) {
      const error = e as AxiosError<ApiErrorResponse>;
      const apiEror = error.response?.data.error;

      this.isInvalidPhoneNumber = apiEror === ApiError.INVALID_ARGUMENTS;
      this.loading = false;
      this.dialogConfirmUpdatePhoneNumber = false;

      this.formRef.validate();
    }
  }

  private created() {
    const {
      country = "",
      prefix = "",
      number = "",
      approved,
    } = this.phoneNumber;

    this.localPhoneNumber.country = country;
    this.localPhoneNumber.prefix = prefix;
    this.localPhoneNumber.number = number;
    this.localPhoneNumber.approved = approved;
  }
}
